import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../core-module/services/authentication";
import { ApiService } from "src/app/core-module/services/api.service";
import { ActivatedRoute } from "@angular/router";
import { LoginComponent } from "../login-component/login.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigureDeviceModalComponent } from "../configure-device-modal/configure-device-modal.component";
import { ReadDeviceComponent } from "../read-device/read-device.component";
import { catchError, flatMap, takeUntil } from "rxjs/operators";
import { Subject, Subscription, throwError } from "rxjs";
import { isNullOrUndefined } from "util";
import { ErrorComponent } from "src/app/error/error.component";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  loading: boolean;
  loggedIn: boolean;
  returnUrl: any;
  redirectUrl = window.location.origin;
  SSOUrl;
  afterLogin: boolean;
  newsLinks = "https://www.signify.com/global/our-company/news";
  dcsRedirectLink =
    "https://www.tools.mytechnology.portal.signify.com/.rest/file/v1/";
  dcsDownloadLink = "https://signify-portal-acc.trimm.net/.rest/file/v1/";
  verLinks = "./assets/pdf/User manual Multione web.pdf";
  isReadDevice = false;
  getReadDeviceSubjectSub: Subscription;
  statusCallData;
  unsubscribe = new Subject<any>();
  readerBaseUrl: any[];
  readerListUrl = "/peripheral/v1/types";
  pathVal: string;
  isDeviceConnected: boolean = false;
  token: any;
  statusCallDataSwVersion: any;
  latestDcsVersion: any;
  isDcsConnected: boolean = true;
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal
  ) {}
  openInfo(e) {
    e.stopPropagation();
  }
  ngOnInit() {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    localStorage.setItem("prodInfoStatus", "no");
    localStorage.setItem("showContinueNow", "no");
    localStorage.setItem("readContinue", "no");
    this.route.queryParams.subscribe((params) => {
      if (params["response"]) {
        this.authenticationService.LoginusingRToken(params["response"]);
      }
    });
    this.authenticationService.isTokenExpired();
    this.authenticationService.startTokenExpiredCheck();
    localStorage.setItem("newDeviceBoolen", JSON.stringify(true));
    localStorage.setItem("reading", JSON.stringify(false));
    this.apiService.removeModes("invalid");
    let envURL = this.apiService.getEnvirnomentUrl(window.location.hostname);
    if (
      envURL
        .toString()
        .includes("https://signify-portal-oem-tools-acc.trimm.net")
    ) {
      this.dcsRedirectLink =
        "https://signify-portal-acc.trimm.net/.rest/file/v1/";
    } else if (
      envURL
        .toString()
        .includes("https://www.tools.mytechnology.portal.signify.com") ||
      envURL.toString().includes("https://signify-portal-prod.trimm.net")
    ) {
      this.dcsRedirectLink =
        "https://www.tools.mytechnology.portal.signify.com/.rest/file/v1/";
    } else {
      this.dcsRedirectLink =
        "https://www.tools.mytechnology.portal.signify.com/.rest/file/v1/";
    }
    if (window.location.hostname == "localhost") {
      this.SSOUrl = envURL + this.redirectUrl + "/home";
    } else {
      this.SSOUrl = envURL + this.redirectUrl + "/deviceList";
    }
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/home";
    this.route.queryParams.subscribe((params) => {
      if (params["response"]) {
        this.LoginusingToken(params["response"]);
      }
    });
    window.scrollTo(0, 0);
    this.apiService.setConfig("");
    this.apiService.getLogoutSubject.subscribe((data) => {
      this.loggedIn = data;
    });
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      if (!isNullOrUndefined(currentUser.token)) {
        this.token = currentUser.token;
        this.getLatestDcsVersion().subscribe(
          (response: any) => {
            this.latestDcsVersion = response.version.split(".").join("");
            localStorage.setItem("latestDcsVersion", this.latestDcsVersion);
          },
          (err) => {
            const modalRef = this.modalService.open(ErrorComponent, {
              keyboard: false,
              centered: true,
              windowClass: "error-style, error-style1",
            });
            modalRef.componentInstance.errorName = "DCS Download Failed";
            modalRef.componentInstance.errorDescription = "Please contact your local Signify representative!";
          }
        );
      }
      localStorage.removeItem("saveSetData");
      this.loggedIn = true;
      this.loading = true;
      this.apiService.getBaseUrl();
      this.apiService.getReadData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length > 0) {
            this.readerBaseUrl = url;
            this.unsubscribe.next();
            let status = this.getDeviceReader();
            let readerSelection = this.getReaderList();
            status
              .pipe(
                flatMap((data) => {
                  this.statusCallData = data;
                  this.statusCallDataSwVersion =
                    this.statusCallData.serverSwVersion
                      .toString()
                      .split(".")
                      .join("");
                  localStorage.setItem("statusCallDataSwVersion", this.statusCallDataSwVersion);
                  this.latestDcsVersion = JSON.parse(
                    localStorage.getItem("latestDcsVersion")
                  );
                  if (
                    !isNullOrUndefined(this.latestDcsVersion) &&
                    !isNullOrUndefined(this.statusCallDataSwVersion)
                  ) {
                    if (
                      parseInt(this.latestDcsVersion) >
                      parseInt(this.statusCallDataSwVersion)
                    ) {
                      this.isReadDevice = false;
                      this.loggedIn = true;
                    } else {
                      this.isReadDevice = true;
                    }
                  }
                  //this.isReadDevice = true;

                  return readerSelection.pipe(
                    catchError((err) => {
                      // do stuff with err from API1 request
                      return throwError(err);
                    })
                  );
                }
              ),
                catchError((err) => {
                  // this.isReadDevice = false;
                  // do stuff with err from API2 request
                  return throwError(err);
                })
              )
              .subscribe(
                (resp) => {
                  this.isDeviceConnected = true;
                  let readerData: any;
                  readerData = resp;
                  localStorage.setItem(
                    "readerDevices",
                    JSON.stringify(readerData)
                  );

                  let readers = JSON.parse(
                    localStorage.getItem("detectedReaderInfo")
                  );

                  let newReader = false;
                  if (
                    !isNullOrUndefined(readers && readers["selectedReader"]) &&
                    readerData[0]["peripheralType"] !==
                      readers["selectedReader"]["peripheralType"]
                  ) {
                    newReader = true;
                  }

                  if (
                    !readers ||
                    (readers && isNullOrUndefined(readers["selectedReader"])) ||
                    newReader
                  ) {
                    let detectedReader = {
                      readerType: "SimpleSet",
                      selectedReader: readerData[0],
                      readerConnected: true,
                    };

                    localStorage.setItem(
                      "detectedReaderInfo",
                      JSON.stringify(detectedReader)
                    );

                    let selevtedReadArr: any[] = [];
                    selevtedReadArr.push(readerData[0]);
                    localStorage.setItem(
                      "readerInfo",
                      JSON.stringify(selevtedReadArr)
                    );
                  }

                  let detectedReaders = JSON.parse(localStorage.getItem("detectedReaderInfo"));
                  detectedReaders['isDcsConnected'] = true;
                  localStorage.setItem(
                    "detectedReaderInfo",
                    JSON.stringify(detectedReaders)
                  );
                  this.loading = false;
                },
                (error) => {
                  if (
                    error &&
                    !isNullOrUndefined(error.status) &&
                    error.status === "404.1"
                  ) {
                    this.isDeviceConnected = false;
                  }

                  if (
                    error &&
                    !isNullOrUndefined(error.target && error.target.url) &&
                    error.target.url === "http://localhost:2020/server/v1/status"
                  ) {
                    this.isDcsConnected = false;
                  }else {
                    this.isDcsConnected = true;
                  }

                  localStorage.removeItem("readerDevices");
                  let detectedReader = {
                    readerType: "SimpleSet",
                    selectedReader: null,
                    readerConnected: false,
                    isDcsConnected : this.isDcsConnected
                  };

                  localStorage.setItem(
                    "detectedReaderInfo",
                    JSON.stringify(detectedReader)
                  );

                  this.loading = false;
                }
              );
          }
        });
      this.router.navigate(["/home"]);
    }
    this.apiService.getReadDeviceStatusViaSimpleSet.subscribe((dcsActive) => {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      if (currentUser) {
        this.loggedIn = true;
       // this.isReadDevice = !isNullOrUndefined(dcsActive) ? dcsActive : false;
      }
    });

    this.getReadDeviceSubjectSub = this.apiService.getReadDeviceSubject.subscribe((response)=>{
      this.isReadDevice = response;
    });
  }

  ngDoCheck() {
    if (
      localStorage.getItem("detectedReaderInfo") &&
      localStorage.getItem("detectedReaderInfo") != "undefined"
    ) {
      let readers = JSON.parse(localStorage.getItem("detectedReaderInfo"));

      if (readers && !isNullOrUndefined(readers["selectedReader"])) {
          this.isDeviceConnected = true;
      } else {
        this.isDeviceConnected = false;

      }
      this.isDcsConnected = readers.isDcsConnected;
    } else {
      this.isDeviceConnected = false;
    }

    if(JSON.parse(
      localStorage.getItem("latestDcsVersion")
    )){
      this.latestDcsVersion = JSON.parse(
        localStorage.getItem("latestDcsVersion")
      );
      if (
        !isNullOrUndefined(this.latestDcsVersion) &&
        !isNullOrUndefined(this.statusCallDataSwVersion)
      ) {
        if (
          parseInt(this.latestDcsVersion) >
          parseInt(this.statusCallDataSwVersion)
        ) {
          this.isReadDevice = false;
        } else {
          this.isReadDevice = true;
        }
      } 
    }

  }

  getDeviceReader() {
    return this.apiService.getServerStatus(this.readerBaseUrl);
    // return this.service.apiCall('get', '/assets/reader/status.json');
  }

  getReaderList() {
    return this.apiService.apiCall(
      "get",
      this.readerBaseUrl + this.readerListUrl
    );
  }
  getLatestDcsVersion() {
    let paramObj = new Object();
    paramObj = {
      token: this.token,
      filename: "/oem-tools/multione-web/multione-dcs.zip",
    };
    return this.apiService.apiCall(
      "post",
      this.dcsRedirectLink + "get-version",
      paramObj
    );
  }
  callTodownloadDcs() {
    let paramObj = new Object();
    paramObj = {
      token: this.token,
      filename: "/oem-tools/multione-web/multione-dcs.zip",
    };
    return this.apiService.apiCall(
      "post",
      this.dcsRedirectLink + "download",
      paramObj,
      {
        responseType: "arraybuffer",
      }
    );
  }
  downloadDcs() {
    this.callTodownloadDcs().subscribe(
      (data) => {
        const blob = new Blob([data], {
          type: "application/zip",
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (err) => {
        const modalRef = this.modalService.open(ErrorComponent, {
          keyboard: false,
          centered: true,
          windowClass: "error-style, error-style1",
        });
        modalRef.componentInstance.errorName = "DCS Download Failed";
        modalRef.componentInstance.errorDescription = "Please contact your local Signify representative!";
      }
    );
  }
  LoginusingToken(jwtToken) {
    var response: any;
    var mowebDecodeToken: any;
    var decodeToken: any;
    mowebDecodeToken = JSON.parse(atob(jwtToken));
    localStorage.setItem("refreshToken", mowebDecodeToken.refresh);
    decodeToken = JSON.parse(atob(mowebDecodeToken.token.split(".")[1]));
    response = { token: mowebDecodeToken.token };
    this.authenticationService.currentUserSubject.next(response);
    this.loading = false;
    this.loggedIn = false;
    let now = Date.now();
    let schedule = now + 60 * 60 * 1000 * 2;
    localStorage.setItem("expiresIn", JSON.stringify(schedule));
    localStorage.setItem("loggedIn", "true");
    localStorage.setItem("currentUser", JSON.stringify(response));
    localStorage.setItem("Name", decodeToken.name);
    localStorage.removeItem("tokenRefresh");
    this.authenticationService.setCurrentUser(decodeToken.name);
    this.router.navigate([this.returnUrl]);
  }

  deivcePage() {
    if (this.authenticationService.currentUserValue) {
      localStorage.setItem("reading", JSON.stringify(false));
      localStorage.removeItem("CCTBoolen");
      localStorage.removeItem("saveSetData");
      localStorage.removeItem("saveReadValue");
      localStorage.removeItem("supporteFeatures");
      localStorage.removeItem("configurationData");
      localStorage.removeItem("readerDeviceFullInfo");
      this.apiService.removeModes("invalid");
      this.router.navigate(["/deviceList"]);
    }
  }

  uploadFeatureFile() {
    return;
    if (this.authenticationService.currentUserValue) {
      localStorage.setItem("reading", JSON.stringify(false));
      localStorage.removeItem("saveSetData");
      localStorage.removeItem("CCTBoolen");
      localStorage.removeItem("saveReadValue");
      localStorage.removeItem("supporteFeatures");
      localStorage.removeItem("configurationData");
      localStorage.removeItem("readerDeviceFullInfo");
      localStorage.setItem("normalFlow", JSON.stringify(true));
      this.apiService.removeModes("invalid");
      // this.apiService.sendModes('Guided');
      let url = window.location.href;
      let isLoggedin = JSON.parse(localStorage.getItem("loggedIn"));
      if (isLoggedin != true) {
        this.router.navigate(["/deviceList"]);
      } else {
        this.apiService.modalConfigurationData("uploadFeatureFile");
        const modalRef = this.modalService.open(ConfigureDeviceModalComponent, {
          windowClass: "select-feature-modal",
          backdropClass: "customBackdrop",
        });
      }
    }
  }

  newsLink() {
    window.open(this.newsLinks, "_blank");
  }

  versionLink() {
    window.open(this.verLinks, "_blank");
  }

  readDevice() {
    if (this.authenticationService.currentUserValue && this.isReadDevice) {
      localStorage.setItem("normalFlow", JSON.stringify(false));
      localStorage.removeItem("CCTBoolen");
      localStorage.removeItem("saveReadValue");
      localStorage.removeItem("resultFeatures");
      localStorage.removeItem("supporteFeatures");
      localStorage.removeItem("configurationData");
      localStorage.removeItem("readerDeviceFullInfo");
      localStorage.removeItem("InputPower1001");
      localStorage.removeItem("InputPower100");
      localStorage.removeItem("InputPowerDim");
      localStorage.removeItem("DimLevel");
      this.apiService.removeModes("invalid");
      localStorage.setItem("reading", JSON.stringify(true));
      localStorage.removeItem("saveSetData");
      let isLoggedin = JSON.parse(localStorage.getItem("loggedIn"));
      if (isLoggedin != true) {
        this.router.navigate(["/deviceList"]);
      } else {
        localStorage.setItem("isWriting", JSON.stringify(false));
        this.apiService.modalConfigurationData("readDevice");
        const modalRef = this.modalService.open(ReadDeviceComponent, {
          windowClass: "select-feature-modal",
          backdropClass: "customBackdrop",
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.getReadDeviceSubjectSub) {
      this.getReadDeviceSubjectSub.unsubscribe();
    };
  }


}
